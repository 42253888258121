@import "~@tdcerhverv/sass-utils/_normalize.scss";
@import "~@tdcerhverv/sass-utils/_font.scss";
@import "~@tdcerhverv/parrotfish/dist/colors-design-token.scss";

html,
body {
  height: auto;
  font-size: 16px;
  min-height: 100%;
}

main {
  background: map-get($theme, "grey-light");
  min-height: 100vh;
}

a {
  cursor: pointer;
}

button {
  cursor: pointer;
}

ul {
  padding-left: 0;

  li {
    margin-bottom: 0;
  }
}

hr {
  margin: 0;
}
